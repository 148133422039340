import styles from "./assets/css/Episodes.module.css";
import useFetch from "../../Hooks/useFetch";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Episodes = ({ title = "", pageSize = 8, paginate = true }) => {
  const [currentPage, setCurrentPage] = useState(() => {
    const savedPage = sessionStorage.getItem("currentPage");
    return savedPage ? Number(savedPage) : 1;
  });
  const [sortOrder, setSortOrder] = useState("desc");
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [dynamicPageSize, setDynamicPageSize] = useState(pageSize);
  const [episodeType, setEpisodeType] = useState("all");
  const { data: episodes } = useFetch(
    `${process.env.REACT_APP_CMS_URL}/episodes?pagination[page]=${currentPage}` +
      `&sort[0]=pub_date:${sortOrder}` +
      `&pagination[pageSize]=${dynamicPageSize}` +
      (episodeType !== "all"
        ? `&filters[episode_type][$eq]=${episodeType}`
        : "")
  );
  const [playlist, setPlaylist] = useState([]);

  const handleChangeOrder = () => {
    setSortOrder((current) => (current === "desc" ? "asc" : "desc"));
  };

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (paginate && viewportWidth >= 1024 && viewportWidth < 1440)
      setDynamicPageSize(9);
    else setDynamicPageSize(pageSize);
  }, [viewportWidth, pageSize, paginate]);

  const handlePageChange = (dir) => {
    if (dir === "prev") {
      setCurrentPage((current) => current - 1);
    } else if (dir === "next") {
      setCurrentPage((current) => current + 1);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, [currentPage]);

  const addToPlaylist = (
    e,
    id,
    title,
    audioURL,
    imageURL,
    duration,
    pubDate,
    description
  ) => {
    e.preventDefault();
    const currentPlaylist = JSON.parse(localStorage.getItem("playlist"));
    if (!currentPlaylist) {
      localStorage.setItem(
        "playlist",
        JSON.stringify([
          { id, title, audioURL, imageURL, duration, pubDate, description },
        ])
      );
    } else {
      const existingEntry = currentPlaylist.filter(
        (episode) => episode.id === id
      );

      if (existingEntry.length > 0) return console.log("Already In Playlist");
      currentPlaylist.push({
        id,
        title,
        audioURL,
        imageURL,
        duration,
        pubDate,
        description,
      });
      localStorage.setItem("playlist", JSON.stringify(currentPlaylist));
    }

    window.dispatchEvent(new Event("playlistUpdated"));
  };

  useEffect(() => {
    sessionStorage.setItem("currentPage", currentPage);
  }, [currentPage]);

  useEffect(() => {
    window.addEventListener("playlistUpdated", storageEvent);

    return () => window.removeEventListener("playlistUpdated", storageEvent);
  }, []);

  const storageEvent = (e) => {
    if (!e.key === "playlist") return;
    setPlaylist(JSON.parse(localStorage.getItem("playlist")));
  };

  useEffect(() => {
    setPlaylist(JSON.parse(localStorage.getItem("playlist")));
  }, []);

  return (
    <section className={styles.episodesSection}>
      <div className="container">
        <h1 className={styles.title}>{title}</h1>
        {paginate && (
          <div className={styles.sortContainer}>
            <div>
              <p>Sort By:</p>
              <button
                className={styles.orderButton}
                type="button"
                onClick={handleChangeOrder}
                title="Click to toggle between newest to oldest, and oldest to newest"
              >
                {sortOrder === "desc" ? "Newest First" : "Oldest First"}
              </button>
            </div>
            <div>
              <p>Episode Type:</p>
              <select
                onChange={(e) => setEpisodeType(e.target.value)}
                className={styles.episodeTypeSelect}
              >
                <option value="all" defaultValue={"all"}>
                  All
                </option>
                <option value="full">Episodes</option>
                <option value="bonus">Specials</option>
              </select>
            </div>
          </div>
        )}
        <div className={styles.episodes}>
          {episodes &&
            episodes.data.map((episode) => (
              <Link
                to={`/episodes/${episode.id}`}
                key={episode.id}
                className={styles.episode}
              >
                <div className={styles.coverArtContainer}>
                  <img
                    className={styles.coverArt}
                    src={episode.attributes.image_url}
                    alt={episode.attributes.title + " cover"}
                  />
                  {playlist &&
                  playlist.filter((x) => x.id === episode.id).length > 0 ? (
                    ""
                  ) : (
                    <button
                      className={styles.addToList}
                      onClick={(e) =>
                        addToPlaylist(
                          e,
                          episode.id,
                          episode.attributes.title,
                          episode.attributes.audio_url,
                          episode.attributes.image_url,
                          episode.attributes.duration,
                          episode.attributes.pub_date,
                          episode.attributes.description
                        )
                      }
                    >
                      <i className="fa-solid fa-list-ol"></i>
                      <i className="fa-solid fa-plus"></i>
                    </button>
                  )}
                </div>
                <div className={styles.details}>
                  <h2 className={styles.name}>{episode.attributes.title}</h2>
                  <p className={styles.excerpt}>{episode.attributes.excerpt}</p>
                </div>
              </Link>
            ))}
        </div>
        {paginate ? (
          episodes && (
            <ul className={styles.pagination}>
              <button
                className={styles.prevNextButton}
                disabled={episodes.meta.pagination.page === 1}
                onClick={() => handlePageChange("prev")}
              >
                <i className="fa-solid fa-chevron-left"></i>
              </button>
              {[...Array(episodes.meta.pagination.pageCount)].map(
                (page, index) => (
                  <li className={styles.pageLink} key={`page index ${index}`}>
                    <button
                      className={`${
                        episodes.meta.pagination.page === index + 1 &&
                        styles.current
                      }`}
                      onClick={() => setCurrentPage(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                )
              )}

              <button
                className={styles.prevNextButton}
                disabled={
                  episodes.meta.pagination.page >=
                  episodes.meta.pagination.pageCount
                }
                onClick={() => handlePageChange("next")}
              >
                <i className="fa-solid fa-chevron-right"></i>
              </button>
            </ul>
          )
        ) : (
          <h2 className={styles.viewAllLink}>
            <Link to="/episodes">View All Episodes</Link>
          </h2>
        )}
      </div>
    </section>
  );
};
export default Episodes;
