import { useEffect, useState } from "react";
import styles from "./assets/css/Nav.module.css";
import logo from "./assets/images/SignalInTheSky.webp";
import { Link, NavLink } from "react-router-dom";

const Nav = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  window.onscroll = (e) => {
    if (window.location.pathname !== "/") return setScrolled(true);
    setScrolled(e.target.scrollingElement.scrollTop !== 0);
  };

  useEffect(() => {
    if (window.location.pathname !== "/") setScrolled(true);
  }, []);

  useEffect(() => {
    document.body.style.overflow = menuOpen ? "hidden" : "auto";
  }, [menuOpen]);

  return (
    <>
      <nav className={`${styles.nav} ${scrolled && styles.scrolled}`}>
        <ul className={styles.navLinks}>
          <li>
            <NavLink to={"/"}>Home</NavLink>
          </li>
          <li>
            <NavLink to={"/episodes"}>Episodes</NavLink>
          </li>
          <li>
            <NavLink to={"/reading-lists"}>Reading List</NavLink>
          </li>
        </ul>
        <div className={styles.menuButtonContainer}>
          <button
            className={`${styles.menuButton} ${
              menuOpen ? styles.show : styles.hide
            }`}
            onClick={() => setMenuOpen((current) => !current)}
          >
            <div className={styles.menuBar}></div>
            <div className={styles.menuBar}></div>
            <div className={styles.menuBar}></div>
          </button>
        </div>
        <nav className={`${styles.mobileMenu} ${menuOpen ? styles.open : ""}`}>
          <ul className={styles.mobileNavLinks}>
            <li>
              <NavLink to={"/"} onClick={() => setMenuOpen(false)}>
                Home
              </NavLink>
            </li>
            {/* <li>
              <NavLink to={"/about"} onClick={() => setMenuOpen(false)}>About</NavLink>
            </li> */}
            <li>
              <NavLink to={"/episodes"} onClick={() => setMenuOpen(false)}>
                Episodes
              </NavLink>
            </li>
            <li>
              <NavLink to={"/reading-lists"} onClick={() => setMenuOpen(false)}>
                Reading List
              </NavLink>
            </li>
          </ul>
          <hr />
          <ul className={styles.socials}>
            <li>
              <a
                href="https://podcasts.apple.com/us/podcast/guys-with-issues/id1696008232"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-apple"></i>
              </a>
            </li>
            <li>
              <a
                href="https://open.spotify.com/show/2vABn7BL9HJgAbQm37lEwv?si=723665f7a1514e12"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-spotify"></i>
              </a>
            </li>
            <li>
              <a
                href="https://music.youtube.com/channel/UCifY64uUvQZ3H2j4nHu18wA"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-square-youtube"></i>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/GuysWithIssues_"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-x-twitter"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=100093268965450"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-facebook"></i>
              </a>
            </li>
            <li>
              <a href="mailto:longbox@guyswithissues.com">
                <i class="fa-solid fa-at"></i>
              </a>
            </li>
          </ul>
        </nav>
      </nav>
      <Link to="/">
        <img
          src={logo}
          className={`${styles.logo} ${scrolled && styles.scrolled}`}
          alt="guys with issues spotlight logo with GWI in the center"
        />
      </Link>
    </>
  );
};
export default Nav;
