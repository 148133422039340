import { Outlet, useLocation } from "react-router-dom";
import Nav from "../../Components/Nav/Nav";
import Footer from "../../Components/Footer/Footer";
import { useEffect } from "react";
import { PlaylistProvider } from "../../Contexts/PlaylistProvider/PlaylistProvider";
import PodcastPlayer from "../../Components/PodcastPlayer/PodcastPlayer";
import ReactGA from "react-ga4";
const TRACKING_ID = process.env.REACT_APP_TRACKING_ID || null;

const RootLayout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!TRACKING_ID) return;
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: pathname });
  }, [pathname]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, [pathname]);
  return (
    <>
      <Nav />
      <Outlet />
      <PlaylistProvider>
        <PodcastPlayer />
      </PlaylistProvider>
      <Footer />
    </>
  );
};
export default RootLayout;
