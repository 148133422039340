import styles from "./assets/css/Footer.module.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className={styles.footer}>
      <ul className={styles.footerNav}>
        <li>
          <Link to={"/"}>Home</Link>
        </li>
        {/* <li>
          <Link to={"/about"}>About</Link>
        </li> */}
        <li>
          <Link to={"/episodes"}>Episodes</Link>
        </li>
        <li>
          <Link to={"/reading-lists"}>Reading List</Link>
        </li>
      </ul>
      <ul className={styles.socials}>
        <li>
          <a
            href="https://podcasts.apple.com/us/podcast/guys-with-issues/id1696008232"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-apple"></i>
          </a>
        </li>
        <li>
          <a
            href="https://open.spotify.com/show/2vABn7BL9HJgAbQm37lEwv?si=723665f7a1514e12"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-spotify"></i>
          </a>
        </li>
        <li>
          <a
            href="https://music.youtube.com/channel/UCifY64uUvQZ3H2j4nHu18wA"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-square-youtube"></i>
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/GuysWithIssues_"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-x-twitter"></i>
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/profile.php?id=100093268965450"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-facebook"></i>
          </a>
        </li>
        <li>
          <a href="mailto:longbox@guyswithissues.com">
            <i class="fa-solid fa-at"></i>
          </a>
        </li>
      </ul>
      <p className={styles.disclaimer}>
        &copy; Guys With Issues All Rights Reserved.
      </p>
    </footer>
  );
};
export default Footer;
