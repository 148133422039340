import { useState, useEffect, useRef } from "react";
import styles from "./assets/css/PodcastPlayer.module.css";

const PodcastPlayer = () => {
  const [showPlayer, setShowPlayer] = useState(false);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [playlist, setPlaylist] = useState([]);
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const audioPlayer = useRef(null);

  const handleTogglePlayer = () => {
    setShowPlayer((current) => !current);
    if (!showPlayer) {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }, 1000);
    } else {
      setHelpDialogOpen(false);
    }
  };

  const handleToggleHelpDialog = () => {
    if (!showPlayer && !helpDialogOpen) {
      handleTogglePlayer();
      setHelpDialogOpen(true);
    } else {
      setHelpDialogOpen((currentState) => !currentState);
    }
  };

  useEffect(() => {
    if (!audioPlayer.current) return;
    audioPlayer.current.addEventListener("timeupdate", () => {
      setCurrentTime(audioPlayer.current.currentTime);
    });
  }, [audioPlayer]);

  useEffect(() => {
    const playlist = JSON.parse(localStorage.getItem("playlist")) || [];
    setPlaylist(playlist);
  }, []);

  window.addEventListener("playlistUpdated", () => {
    const playlist = JSON.parse(localStorage.getItem("playlist")) || [];
    setPlaylist(playlist);
  });

  const secondsToHMS = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = Math.floor(remainingSeconds)
      .toString()
      .padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const getDateString = (date) => {
    const originalDate = new Date(date);

    // Get the user's locale
    const userLocale = navigator.language || "en-US";

    // Format the date according to the user's locale
    return originalDate.toLocaleDateString(userLocale, {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
  };

  return (
    <section
      className={`
    ${styles.podcastPlayerSection} 
    ${showPlayer ? styles.show : ""}
    `}
    >
      <button
        type="button"
        className={styles.menuButton}
        aria-label="toggle menu"
        onClick={handleTogglePlayer}
      >
        {showPlayer ? "Close Player" : "Open Player"}
      </button>
      <button
        type="button"
        className={styles.helpButton}
        aria-label="open help dialog"
        onClick={handleToggleHelpDialog}
      >
        ?
      </button>
      <div
        className={`${styles.helpDialog} ${
          helpDialogOpen && styles.helpDialogOpen
        }`}
        open
      >
        <p>
          Click on the add to playlist button{" "}
          <span>
            ( <i class="fa-solid fa-list-ol"></i> + )
          </span>{" "}
          found on each episode.
        </p>
        <p>Hit play, and sit back and relax...</p>
      </div>
      <div className={styles.details}>
        <h3 className={styles.title}>
          {playlist.length > 0 ? playlist[0].title : "Guys With Issues Podcast"}
        </h3>
        <p className={styles.pubDate}>
          <i className="fa-regular fa-calendar"></i>{" "}
          <span>
            {playlist.length > 0
              ? getDateString(playlist[0].pubDate)
              : "- - / - - / - - - -"}
          </span>
        </p>
        <p className={styles.time}>
          <span id="currentTime">
            {audioPlayer.current ? secondsToHMS(currentTime) : "00:00:00"}
          </span>{" "}
          /{" "}
          <span id="duration">
            {playlist.length > 0
              ? secondsToHMS(playlist[0].duration)
              : "- - : - - : - -"}
          </span>
          <i className="fa-solid fa-stopwatch"></i>
        </p>
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.imageContainer}>
          {playlist.length > 0 ? (
            <img
              className={styles.image}
              src={playlist[0].imageURL}
              alt="Guys With Issues Podcast cover art"
            />
          ) : (
            <img
              className={styles.image}
              src="https://media.rss.com/guys-with-issues/podcast_cover.png"
              alt="Guys With Issues Podcast cover art"
            />
          )}
        </div>
        {playlist.length > 0 ? (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: playlist[0].description }}
          ></div>
        ) : (
          <div className={styles.description} style={{ fontSize: "1rem" }}>
            <p>Punching, plot holes, and post-credit scenes!</p>{" "}
            <p>
              For well over a decade, superheroes have overtaken the box office
              and our hearts as many of us encountered them for the first time
              on the big screen.
            </p>
            <p>
              But, as the credits roll, have you ever found yourself wanting
              more? Guys With Issues does! Read along with Patrick, Will, and
              Chris as they dive into the worlds of the comic books that
              inspired the movies that we've all fallen in love with.
            </p>
            <p>
              In this “comic book book club,” Guys With Issues read acclaimed
              story arcs and discuss their thoughts and opinions on the tales
              that created a multibillion-dollar phenomenon. New to the idea of
              reading comic books?
            </p>
            <p>
              This is the perfect place to turn your first pages. Long-time
              collector? Read along to dust off those arcs from yesteryear. From
              Marvel to DC and far beyond, no story is safe from the bumbling
              opinions of Guys With Issues and all who venture to join in the
              fun.
            </p>
          </div>
        )}
      </div>
      <input
        type="range"
        min={0}
        max={playlist.length > 0 ? playlist[0].duration : 0}
        value={currentTime ? currentTime : 0}
        step={1}
        onChange={(e) => {
          audioPlayer.current.currentTime = e.target.value;
          setCurrentTime(e.target.value);
        }}
      />

      <div className={styles.playerButtons}>
        <button
          type="button"
          onClick={() => {
            audioPlayer.current.currentTime -= 15;
          }}
        >
          <i className="fa-solid fa-rotate-left"></i>
        </button>
        <button
          type="button"
          onClick={() => {
            setPlaying((current) => !current);
            if (playing) {
              audioPlayer.current.pause();
            } else {
              audioPlayer.current.play();
            }
          }}
        >
          {playing ? (
            <i className="fa-solid fa-pause"></i>
          ) : (
            <i className="fa-solid fa-play"></i>
          )}
        </button>
        <button
          type="button"
          onClick={() => {
            audioPlayer.current.currentTime += 15;
          }}
        >
          <i className="fa-solid fa-rotate-right"></i>
        </button>
      </div>
      <button
        className={styles.clearPlaylist}
        onClick={() => {
          localStorage.removeItem("playlist");
          setPlaylist([]);
          window.dispatchEvent(new Event("playlistUpdated"));
        }}
      >
        Clear Playlist
      </button>
      <audio
        ref={audioPlayer}
        src={playlist.length > 0 ? playlist[0].audioURL : ""}
        onEnded={() => {
          setPlaying(false);
          setCurrentTime(0);
          // remove episode from playlist
          const newPlaylist = playlist.filter((_, index) => index !== 0);
          localStorage.setItem("playlist", JSON.stringify(newPlaylist));
          window.dispatchEvent(new Event("playlistUpdated"));
        }}
      ></audio>
    </section>
  );
};
export default PodcastPlayer;
