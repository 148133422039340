import useFetch from "../../Hooks/useFetch";
import styles from "./assets/css/Episode.module.css";
import { useParams } from "react-router-dom";

const Episode = () => {
  const { episodeId } = useParams();

  const { data: episode } = useFetch(
    `${process.env.REACT_APP_CMS_URL}/episodes/${episodeId}`
  );

  const toLocalDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
  };

  const formatTime = (totalSeconds) => {
    // Calculate hours, minutes and seconds
    var hours = Math.floor(totalSeconds / 3600);
    var minutes = Math.floor((totalSeconds % 3600) / 60);
    var seconds = totalSeconds % 60;

    // Pad minutes and seconds with leading zeros if needed
    minutes = minutes.toString().padStart(2, "0");
    seconds = seconds.toString().padStart(2, "0");

    // Format the output
    if (hours > 0) {
      return hours + ":" + minutes + ":" + seconds;
    } else {
      return minutes + ":" + seconds;
    }
  };

  return (
    <>
      <section className={styles.episodeSection}>
        <div className="container">
          {episode && (
            <div className={styles.episode}>
              <div>
                <div className={styles.imageContainer}>
                  <img
                    src={episode.data.attributes.image_url}
                    alt={episode.data.attributes.title + " cover art"}
                  />
                </div>
                <div className={styles.statsMobile}>
                  <p className={styles.pubDate}>
                    <i className="fa-regular fa-calendar"></i>{" "}
                    {toLocalDate(episode.data.attributes.pub_date)}
                  </p>
                  <p className={styles.duration}>
                    {formatTime(episode.data.attributes.duration)}{" "}
                    <i className="fa-solid fa-stopwatch"></i>
                  </p>
                </div>
              </div>
              <div>
                <h1 className={styles.title}>
                  {episode.data.attributes.title}
                </h1>
                <div className={styles.stats}>
                  <p className={styles.pubDate}>
                    <i className="fa-regular fa-calendar"></i>{" "}
                    {toLocalDate(episode.data.attributes.pub_date)}
                  </p>
                  <p className={styles.duration}>
                    <i className="fa-solid fa-stopwatch"></i>{" "}
                    {formatTime(episode.data.attributes.duration)}
                  </p>
                </div>
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: episode.data.attributes.description.replace(
                      "\n",
                      "\n\n"
                    ),
                  }}
                ></div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};
export default Episode;
