import * as React from "react";
import * as ReactDOM from "react-dom/client";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

// Layouts
import RootLayout from "./Layouts/RootLayout/RootLayout";

// Pages
import Home from "./Pages/Home/Home";
import Episodes from "./Components/Episodes/Episodes";
import Episode from "./Pages/Episode/Episode";
import ReadingList from "./Pages/ReadingList/ReadingList";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
        children: [
          {
            path: "/",
            element: (
              <Episodes
                title={"Latest Episodes"}
                pageSize={4}
                paginate={false}
              />
            ),
          },
        ],
      },
      {
        path: "/episodes",
        element: <Episodes title="All Episodes" />,
      },
      {
        path: "/episodes/:episodeId",
        element: <Episode />,
      },
      {
        path: "/reading-lists",
        element: <ReadingList />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

serviceWorkerRegistration.unregister();

reportWebVitals();
