import { useEffect, useState } from "react";
import useFetch from "../../Hooks/useFetch";
import styles from "./assets/css/ReadingList.module.css";
import placeHolderImage from "./assets/img/reading_list_placeholder_image.webp";

const ReadingList = ({ pageSize = 12, paginate = true }) => {
  const [currentPage, setCurrentPage] = useState(() => {
    const savedPage = sessionStorage.getItem("currentPageReadingList");
    return savedPage ? Number(savedPage) : 1;
  });
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [flippedCardIndex, setFlippedCardIndex] = useState(null);
  const [sortOrder, setSortOrder] = useState("desc");
  const [dynamicPageSize, setDynamicPageSize] = useState(pageSize);
  const { data } = useFetch(
    `${process.env.REACT_APP_CMS_URL}/reading-lists?populate=*` +
      `&pagination[page]=${currentPage}` +
      `&sort[0]=episode.pub_date:${sortOrder}` +
      `&pagination[pageSize]=${dynamicPageSize}`
  );

  console.log(data);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (paginate && viewportWidth >= 1024 && viewportWidth < 1440)
      setDynamicPageSize(9);
    else setDynamicPageSize(pageSize);
  }, [viewportWidth, pageSize, paginate]);

  // Handle click event that adds class flip to the clicked episode
  const handleClick = (index) => {
    setFlippedCardIndex(index);
    // reset the flipped card index if the same card is clicked
    if (flippedCardIndex === index) {
      setFlippedCardIndex(null);
    }
  };

  const handleChangeOrder = () => {
    setSortOrder((current) => (current === "desc" ? "asc" : "desc"));
  };

  const handlePageChange = (dir) => {
    if (dir === "prev") {
      setCurrentPage((current) => current - 1);
    } else if (dir === "next") {
      setCurrentPage((current) => current + 1);
    }
  };

  return (
    <section className={styles.readingListSection}>
      <div className="container">
        <h2 className={styles.readingListTitle}>
          GWI <span className={styles.redText}>Reading List</span>
        </h2>
        <div className={styles.readingListDescription}>
          <p>
            Explore our podcast episodes and find the comic books you need to
            read along with us.
          </p>
          <p>Simply click on an episode to reveal its reading list.</p>
        </div>
        <div className={styles.sortContainer}>
          <div>
            <p>Sort By:</p>
            <button
              className={styles.orderButton}
              type="button"
              onClick={handleChangeOrder}
              title="Click to toggle between newest to oldest, and oldest to newest"
            >
              {sortOrder === "desc" ? "Newest First" : "Oldest First"}
            </button>
          </div>
        </div>
        <div className={styles.episodes}>
          {data &&
            data?.data.map((readingList, index) => (
              <div className={styles.flipCard} key={index}>
                <div
                  className={`${styles.flipCardInner} ${
                    flippedCardIndex === index ? styles.flip : ""
                  }`}
                  onClick={() => handleClick(index)}
                >
                  <div className={styles.flipCardFront}>
                    {readingList.attributes.episode.data ? (
                      <img
                        src={
                          readingList.attributes.episode.data.attributes
                            .image_url
                        }
                        alt="episode cover"
                        className={styles.coverArt}
                      />
                    ) : (
                      <img
                        src={placeHolderImage}
                        alt="episode cover"
                        className={styles.coverArt}
                      />
                    )}
                  </div>
                  <div className={styles.flipCardBack}>
                    <h3 className={styles.episodeTitle}>
                      {readingList.attributes.episode.data
                        ? readingList.attributes.episode.data.attributes.title
                        : "Next Weeks Reading List"}
                    </h3>
                    {readingList.attributes.reading_list_link.map(
                      (link, index) =>
                        link.link_url ? (
                          <a
                            key={index}
                            href={link.link_url}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.readingListLink}
                          >
                            {link.link_text}
                          </a>
                        ) : (
                          <p key={index} className={styles.readingListText}>
                            {link.link_text}
                          </p>
                        )
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      {data && (
        <ul className={styles.pagination}>
          <button
            className={styles.prevNextButton}
            disabled={data.meta.pagination.page === 1}
            onClick={() => {
              handlePageChange("prev");
              setFlippedCardIndex(null);
            }}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          {[...Array(data.meta.pagination.pageCount)].map((page, index) => (
            <li className={styles.pageLink} key={`page index ${index}`}>
              <button
                className={`${
                  data.meta.pagination.page === index + 1 && styles.current
                }`}
                onClick={() => {
                  setCurrentPage(index + 1);
                  setFlippedCardIndex(null);
                }}
              >
                {index + 1}
              </button>
            </li>
          ))}

          <button
            className={styles.prevNextButton}
            disabled={
              data.meta.pagination.page >= data.meta.pagination.pageCount
            }
            onClick={() => {
              handlePageChange("next");
              setFlippedCardIndex(null);
            }}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </button>
        </ul>
      )}
    </section>
  );
};
export default ReadingList;
