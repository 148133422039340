import styles from "./assets/css/Home.module.css";
import { Outlet } from "react-router-dom";
import cloudLarge from "./assets/images/cloud_large.webp";
import cloudMedium_lit from "./assets/images/cloud_medium_lit.webp";
import cloudSmall from "./assets/images/cloud_small.webp";
import cloudSmallLit from "./assets/images/cloud_small_lit.webp";
import buildings from "./assets/images/Buildings.webp";
import neonSign from "./assets/images/NeonSign.webp";

const Home = () => {
  return (
    <>
      <section className={styles.hero}>
        <img
          src={cloudLarge}
          className={`${styles.cloud} ${styles.cloud1}`}
          alt=""
        />
        <img
          src={cloudSmall}
          className={`${styles.cloud} ${styles.cloud2}`}
          alt=""
        />
        <img
          src={cloudMedium_lit}
          className={`${styles.cloud} ${styles.cloud3}`}
          alt=""
        />
        <img
          src={cloudSmallLit}
          className={`${styles.cloud} ${styles.cloud4}`}
          alt=""
        />
        <img src={buildings} className={styles.buildings} alt="" />
        <img src={neonSign} className={styles.neonSign} alt="" />
      </section>
      <Outlet />
    </>
  );
};
export default Home;
